<template>
  <b-card class="product-add-wrapper">
    <validation-observer ref="addProductValidation">
      <b-form>
        <!-- <div class="row p-2" style="padding-bottom:0 !important">
          <div class="col-12">
            <b-form-group class>
              <validation-provider #default="{ errors }" rules="required" name="Chọn Nhà Thuốc">
                <h4 class="d-block" style="margin-bottom:8px">
                  Chọn Nhà Thuốc
                  <span class="text-danger">*</span>
                </h4>
                <v-select
                  :options="organizations"
                  :filterable="false"
                  v-model="form.pharma_id"
                  transition
                  :reduce="option=>option.id"
                  label="name"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <feather-icon icon="ChevronDownIcon" size="18" />
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0]?'Nhà thuốc không được để trống':'' }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>-->
        <div class="row match-height">
          <div class="col-lg-6">
            <b-card-actions class="margin-none" no-body title="Thông tin thuốc" action-collapse>
              <b-card class="mb-0 cs-card" bg-variant="light">
                <b-form>
                  <b-row class="mt-1">
                    <!-- <b-col md="4">
                      <b-form-group label="ID thuốc gốc" class="mb-2">
                        <validation-provider #default="{ errors }" name="ID thuốc gốc">
                          <b-form-input type="number" v-model="form.product_master_id" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>-->
                    <b-col md="6">
                      <b-form-group class="mb-2">
                        <validation-provider #default="{ errors }" rules="required" name="Mã thuốc">
                          <span class="d-block" style="margin-bottom:8px">
                            Mã thuốc
                            <span class="text-danger">*</span>
                          </span>

                          <b-form-input v-model="form.product_code" />
                          <small
                            class="text-danger"
                          >{{ errors[0]?"Mã thuốc không được để trống":'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Mã thuốc BHYT" class="mb-2">
                        <validation-provider #default="{ errors }" name="Mã thuốc BHYT">
                          <b-form-input v-model="form.product_hi_code" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group class="mb-2">
                        <validation-provider
                          #default="{ errors }"
                          name="Tên thuốc"
                          rules="required"
                        >
                          <span class="d-block" style="margin-bottom:8px">
                            Tên thuốc
                            <span class="text-danger">*</span>
                          </span>
                          <b-form-input v-model="form.name" />
                          <small
                            class="text-danger"
                          >{{ errors[0]?'Tên thuốc không được để trống':'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group class="mb-2">
                        <validation-provider #default="{ errors }" rules="required" name="Đơn vị">
                          <span class="d-block" style="margin-bottom:8px">
                            Đơn vị
                            <span class="text-danger">*</span>
                          </span>
                          <v-select
                            class="cs-vSelect"
                            :options="unitOptions.options"
                            :filterable="false"
                            @open="handleGetUnitOptions"
                            v-model="form.unit_id"
                            transition
                            label="name"
                            :noDrop="!unitOptions.options.length"
                            :loading="unitOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetUnitOptions,value)"
                            selectOnTab
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>
                          <!-- <b-form-select v-model="form.unit_id" :options="optionsData.unitOptions"></b-form-select> -->
                          <small
                            class="text-danger"
                          >{{ errors[0]?'Quy cách không được để trống':'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Quy cách đóng gói" class="mb-2">
                        <validation-provider #default="{ errors }" name="Quy cách đóng gói">
                          <b-form-input v-model="form.packing" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group class="mb-2">
                        <validation-provider
                          #default="{ errors }"
                          name="Hoạt chất"
                          rules="required"
                        >
                          <span class="d-block" style="margin-bottom:8px">
                            Hoạt chất
                            <span class="text-danger">*</span>
                          </span>

                          <!-- <b-form-select
                            v-model="form.active_ingredient"
                            :options="optionsData.activeIngredientOptions"
                          />-->
                          <v-select
                            class="cs-vSelect"
                            :options="activeIngredientOptions.options"
                            :filterable="false"
                            @open="handleGetActiveIngredientOptions"
                            v-model="form.active_ingredient"
                            transition
                            label="name"
                            :noDrop="!activeIngredientOptions.options.length"
                            :loading="activeIngredientOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetActiveIngredientOptions,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>
                          <small
                            class="text-danger"
                          >{{ errors[0]?'Hoạt chất không được để trống':'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group class="mb-2">
                        <validation-provider #default="{ errors }" name="Hàm lượng">
                          <span class="d-block" style="margin-bottom:8px">Hàm lượng</span>
                          <b-form-input v-model="form.quality" />
                          <small
                            class="text-danger"
                          >{{ errors[0]?'Hàm lượng không được để trống':'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group class="mb-2">
                        <validation-provider #default="{ errors }" name="Nồng độ">
                          <span class="d-block" style="margin-bottom:8px">Nồng độ</span>
                          <b-form-input v-model="form.concentration" />
                          <small
                            class="text-danger"
                          >{{ errors[0]?'Nồng độ không được để trống':'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group class="mb-2">
                        <validation-provider #default="{ errors }" name="Thể tích">
                          <span class="d-block" style="margin-bottom:8px">Thể tích</span>
                          <b-form-input v-model="form.volume" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group class="mb-2">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Đường dùng"
                        >
                          <span class="d-block" style="margin-bottom:8px">
                            Đường dùng
                            <span class="text-danger">*</span>
                          </span>
                          <v-select
                            class="cs-vSelect"
                            :options="routeOfUseOptions.options"
                            :filterable="false"
                            @open="handleGetRouteOfUseOptions"
                            v-model="form.route_of_use"
                            transition
                            label="name"
                            :noDrop="!routeOfUseOptions.options.length"
                            :loading="routeOfUseOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetRouteOfUseOptions,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>
                          <!-- <b-form-select
                            v-model="form.route_of_use"
                            :options="optionsData.routeOfUse"
                          ></b-form-select>-->
                          <small
                            class="text-danger"
                          >{{ errors[0]?'Đường dùng không được để trống':'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Nước sản xuất" class="mb-2">
                        <validation-provider #default="{ errors }" name="Nước sản xuất">
                          <v-select
                            class="cs-vSelect"
                            :options="countryOptions.options"
                            :filterable="false"
                            @open="handleGetCountryOptions"
                            v-model="form.country"
                            transition
                            label="name"
                            :noDrop="!countryOptions.options.length"
                            :loading="countryOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetCountryOptions,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>
                          <!-- <b-form-select
                            v-model="form.country"
                            :options="optionsData.countryOptions"
                          ></b-form-select>-->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Hãng sản xuất" class="mb-2">
                        <validation-provider #default="{ errors }" name="Hãng sản xuất">
                          <v-select
                            class="cs-vSelect cs-vSelect-large"
                            :options="manufacturerOptions.options"
                            :filterable="false"
                            @open="handleGetManufacturersOptions"
                            v-model="form.manufacturer"
                            transition
                            label="name"
                            :noDrop="!manufacturerOptions.options.length"
                            :loading="manufacturerOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetManufacturersOptions,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>

                          <!-- <b-form-select
                            v-model="form.manufacturer"
                            :options="optionsData.manufacturerOptions"
                          ></b-form-select>-->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group class="mb-2">
                        <validation-provider
                          rules="required"
                          #default="{ errors }"
                          name="Nhà cung cấp"
                        >
                          <span class="d-block" style="margin-bottom:8px">
                            Nhà cung cấp
                            <span class="text-danger">*</span>
                          </span>
                          <!-- <b-form-select
                            v-model="form.supplier"
                            :options="optionsData.pharmaSupplier"
                          ></b-form-select>-->
                          <v-select
                            class="cs-vSelect cs-vSelect-large"
                            :options="supplierOptions.options"
                            :filterable="false"
                            @open="handleGetSuppliersOptions"
                            v-model="form.supplier"
                            transition
                            label="supplier_name"
                            :noDrop="!supplierOptions.options.length"
                            :loading="supplierOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetSuppliersOptions,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>
                          <small
                            class="text-danger"
                          >{{ errors[0]?'Nhà cung cấp không được để trống':'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-card-actions>
          </div>
          <div class="col-lg-6">
            <b-card-actions
              class="margin-none"
              no-body
              title="Thông tin quản lý thuốc"
              action-collapse
            >
              <b-card style="min-height:470px" class="mb-0 cs-card" bg-variant="light">
                <b-form class="mt-1">
                  <b-row>
                    <b-col md="3">
                      <b-form-group label="Số lô" class="mb-2">
                        <validation-provider #default="{ errors }" name="Số lô">
                          <b-form-input type="text" v-model="form.lot_number" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Số đăng ký" class="mb-2">
                        <validation-provider #default="{ errors }" name="Số đăng ký">
                          <b-form-input v-model="form.registration_number" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Mã ATC" class="mb-2">
                        <validation-provider #default="{ errors }" name="Mã ATC">
                          <b-form-input v-model="form.atc_code" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="Mã dược quốc gia" class="mb-2">
                        <validation-provider #default="{ errors }" name="Mã dược quốc gia">
                          <b-form-input v-model="form.national_pharmacy_code" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Hạn sử dụng_ngày" class="mb-2">
                        <validation-provider #default="{ errors }" name="Hạn sử dụng_ngày">
                          <b-form-input type="number" v-model="form.expiry_date_day" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường expiry_date_month -->
                    <b-col md="4">
                      <b-form-group label="Hạn sử dụng_tháng" class="mb-2">
                        <validation-provider #default="{ errors }" name="Hạn sử dụng_tháng">
                          <b-form-input type="number" v-model="form.expiry_date_month" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường expiry_date_year -->
                    <b-col md="4">
                      <b-form-group label="Hạn sử dụng_năm" class="mb-2">
                        <validation-provider #default="{ errors }" name="Hạn sử dụng_năm">
                          <b-form-input type="number" v-model="form.expiry_date_year" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Cảnh báo hạn sử dụng" class="mb-2">
                        <validation-provider #default="{ errors }" name="Cảnh báo hạn sử dụng">
                          <b-form-input type="number" v-model="form.expiration_warning" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Cảnh báo tồn kho" class="mb-2">
                        <validation-provider #default="{ errors }" name="Cảnh báo tồn kho">
                          <b-form-input type="number" v-model="form.inventory_warning" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-card-actions>
          </div>
        </div>

        <div class="row match-height">
          <div class="col-lg-6">
            <b-card-actions
              class="margin-none"
              no-body
              title="Loại thuốc và Chi phí"
              action-collapse
            >
              <b-card class="mb-0" style="min-height:380px" bg-variant="light">
                <b-form>
                  <b-row class="mt-1">
                    <b-col md="4">
                      <b-form-group class="mb-2">
                        <validation-provider
                          #default="{ errors }"
                          name="Loại thuốc"
                          rules="required"
                        >
                          <span class="d-block" style="margin-bottom:8px">
                            Loại thuốc
                            <span class="text-danger">*</span>
                          </span>
                          <v-select
                            class="cs-vSelect"
                            :options="productTypeOptions.options"
                            :filterable="false"
                            @open="handleGetProductCateOptions"
                            v-model="form.pharma_product_type_id"
                            transition
                            label="name"
                            :noDrop="!productTypeOptions.options.length"
                            :loading="productTypeOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetProductCateOptions,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>
                          <!-- <b-form-select
                            v-model="form.pharma_product_type_id"
                            :options="optionsData.pharmaProductTypes"
                          ></b-form-select>-->
                          <small class="text-danger">{{ errors[0]?"Loại thuốc không được để trống":'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Nhóm chi phí BHYT" class="mb-2">
                        <validation-provider #default="{ errors }" name="Nhóm chi phí BHYT">
                          <!-- <b-form-select
                            v-model="form.insurance_cost_group"
                            :options="optionsData.INSURANCE_CONST_GROUP"
                          ></b-form-select>-->
                          <v-select
                            class="cs-vSelect"
                            :options="healthCostOptions.options"
                            :filterable="false"
                            @open="handleGetHealthCost"
                            v-model="form.insurance_cost_group"
                            transition
                            label="name"
                            :noDrop="!healthCostOptions.options.length"
                            :loading="healthCostOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetHealthCost,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group class="mb-2">
                        <validation-provider #default="{ errors }" rules="required" name="Giá nhập">
                          <span class="d-block" style="margin-bottom:8px">
                            Giá nhập
                            <span class="text-danger">*</span>
                          </span>
                          <b-input-group append="VNĐ">
                            <Cleave
                              class="form-control"
                              v-model="form.price_in"
                              :id="`input_price_in`"
                              :raw="false"
                              :options="cleaveOptions.number"
                              placeholder="10,000"
                            />
                          </b-input-group>

                          <!-- <b-form-input v-model="form.price_in" /> -->
                          <small
                            class="text-danger"
                          >{{ errors[0]?'Giá nhập không được để trống':'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="VAT nhập(%)" class="mb-2">
                        <validation-provider #default="{ errors }" name="VAT nhập">
                          <b-form-input type="number" v-model="form.vat_in" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group class="mb-2">
                        <validation-provider rules="required" #default="{ errors }" name="Giá bán">
                          <span class="d-block" style="margin-bottom:8px">
                            Giá bán
                            <span class="text-danger">*</span>
                          </span>
                          <b-input-group append="VNĐ">
                            <Cleave
                              class="form-control"
                              v-model="form.price_out"
                              :id="`input_price_out`"
                              :raw="false"
                              :options="cleaveOptions.number"
                              placeholder="10,000"
                            />
                          </b-input-group>
                          <!-- <b-form-input v-model="form.price_out" /> -->
                          <small
                            class="text-danger"
                          >{{ errors[0]?'Giá bán không được để trống':'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường vat_out -->
                    <b-col md="4">
                      <b-form-group label="VAT bán(%)" class="mb-2">
                        <validation-provider #default="{ errors }" name="VAT bán">
                          <b-form-input type="number" v-model="form.vat_out" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Giá BHYT" class="mb-2">
                        <validation-provider #default="{ errors }" name="Giá BHYT">
                          <b-input-group append="VNĐ">
                            <Cleave
                              class="form-control"
                              v-model="form.price_health_insurance"
                              :id="`price_health_insurance`"
                              :raw="false"
                              :options="cleaveOptions.number"
                              placeholder="10,000"
                            />
                          </b-input-group>
                          <!-- <b-form-input v-model="form.price_health_insurance" /> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường price_hospital_fee -->
                    <b-col md="4">
                      <b-form-group label="Giá viện phí" class="mb-2">
                        <validation-provider #default="{ errors }" name="Giá viện phí">
                          <b-input-group append="VNĐ">
                            <Cleave
                              class="form-control"
                              v-model="form.price_hospital_fee"
                              :id="`price_hospital_fee`"
                              :raw="false"
                              :options="cleaveOptions.number"
                              placeholder="10,000"
                            />
                          </b-input-group>
                          <!-- <b-form-input v-model="form.price_hospital_fee" /> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường price_service -->
                    <b-col md="4">
                      <b-form-group label="Giá dịch vụ" class="mb-2">
                        <validation-provider #default="{ errors }" name="Giá dịch vụ">
                          <b-input-group append="VNĐ">
                            <Cleave
                              class="form-control"
                              v-model="form.price_service"
                              :id="`price_service`"
                              :raw="false"
                              :options="cleaveOptions.number"
                              placeholder="10,000"
                            />
                          </b-input-group>
                          <!-- <b-form-input v-model="form.price_service" /> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường percent_insurance_portability -->
                    <b-col md="4">
                      <b-form-group label="% đúng tuyến BHYT" class="mb-2">
                        <validation-provider #default="{ errors }" name="% đúng tuyến BHYT">
                          <b-form-input type="number" v-model="form.percent_insurance_portability" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường percent_insurance_uninsured -->
                    <b-col md="4">
                      <b-form-group label="% trái tuyến BHYT" class="mb-2">
                        <validation-provider #default="{ errors }" name="% trái tuyến BHYT">
                          <b-form-input type="number" v-model="form.percent_insurance_uninsured" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-card-actions>
          </div>
          <div class="col-lg-6">
            <b-card-actions class="margin-none" no-body title="Nhóm thuốc" action-collapse>
              <b-card class="mb-0 cs-card" style="min-height:380px" bg-variant="light">
                <b-form class="mt-1">
                  <b-row>
                    <b-col md="4">
                      <b-form-group label="Nhóm quản lý" class="mb-2">
                        <validation-provider #default="{ errors }" name="Nhóm quản lý">
                          <v-select
                            class="cs-vSelect"
                            :options="groupMgntOptions.options"
                            :filterable="false"
                            @open="handleGetGroupManagers"
                            v-model="form.group_mgnt"
                            transition
                            label="name"
                            :noDrop="!groupMgntOptions.options.length"
                            :loading="groupMgntOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetGroupManagers,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường group_research -->
                    <b-col md="4">
                      <b-form-group label="Nhóm nghiên cứu" class="mb-2">
                        <validation-provider #default="{ errors }" name="Nhóm nghiên cứu">
                          <v-select
                            class="cs-vSelect"
                            :options="groupResearchers.options"
                            :filterable="false"
                            @open="haddleGetGroupResearchers"
                            v-model="form.group_research"
                            transition
                            label="name"
                            :noDrop="!groupResearchers.options.length"
                            :loading="groupResearchers.isLoading"
                            @search="(value)=>handleDebounce(haddleGetGroupResearchers,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường group_pathological -->
                    <b-col md="4">
                      <b-form-group label="Nhóm bệnh lý" class="mb-2">
                        <validation-provider #default="{ errors }" name="Nhóm bệnh lý">
                          <v-select
                            class="cs-vSelect cs-dilution"
                            :options="groupPathologicals.options"
                            :filterable="false"
                            @open="handleGetGroupPathologicals"
                            v-model="form.group_pathological"
                            transition
                            label="name"
                            :noDrop="!groupPathologicals.options.length"
                            :loading="groupPathologicals.isLoading"
                            @search="(value)=>handleDebounce(handleGetGroupPathologicals,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường group_abcven -->
                    <b-col md="4">
                      <b-form-group label="Nhóm ABC/VEN" class="mb-2">
                        <validation-provider #default="{ errors }" name="Nhóm ABC/VEN">
                          <v-select
                            class="cs-vSelect"
                            :options="groupABCVEN.options"
                            :filterable="false"
                            @open="handleGetGroupABCVEN"
                            v-model="form.group_abcven"
                            transition
                            label="name"
                            :noDrop="!groupABCVEN.options.length"
                            :loading="groupABCVEN.isLoading"
                            @search="(value)=>handleDebounce(handleGetGroupABCVEN,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường group_pharmacological -->
                    <b-col md="4">
                      <b-form-group label="Nhóm dược lý" class="mb-2">
                        <validation-provider #default="{ errors }" name="Nhóm dược lý">
                          <v-select
                            class="cs-vSelect"
                            :options="groupPharmacologicals.options"
                            :filterable="false"
                            @open="handleGetGroupPharmacologicals"
                            v-model="form.group_pharmacological"
                            transition
                            label="name"
                            :noDrop="!groupPharmacologicals.options.length"
                            :loading="groupPharmacologicals.isLoading"
                            @input="handlSelectedPharmacological"
                            @search="(value)=>handleDebounce(handleGetGroupPharmacologicals,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường group_sub_pharmacological -->
                    <b-col md="4">
                      <b-form-group label="Nhóm tiểu dược lý" class="mb-2">
                        <validation-provider #default="{ errors }" name="Nhóm tiểu dược lý">
                          <v-select
                            class="cs-vSelect cs-dilution"
                            :options="groupSubPharmacologicals.options"
                            :filterable="false"
                            v-model="form.group_sub_pharmacological"
                            transition
                            label="name"
                            :noDrop="!groupSubPharmacologicals.options.length"
                            :loading="groupSubPharmacologicals.isLoading"
                            @search="(value)=>handleDebounce(handleGetGroupSubPharmacologicals,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường is_lock -->
                    <b-col md="4">
                      <b-form-group label="Trạng thái khóa" class="mb-2">
                        <validation-provider #default="{ errors }" name="Trạng thái khóa">
                          <div class="d-flex align-items-center" style="gap:12px;margin-top:10px">
                            <b-form-radio v-model="form.is_lock" name="unlock" :value="1">Không khoá</b-form-radio>
                            <b-form-radio v-model="form.is_lock" name="lock" :value="2">Khoá</b-form-radio>
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường is_pharmacy -->
                    <b-col md="4">
                      <b-form-group label="Thuốc nhà thuốc" class="mb-2">
                        <validation-provider #default="{ errors }" name="Thuốc nhà thuốc">
                          <div class="d-flex align-items-center" style="gap:12px;margin-top:10px">
                            <b-form-radio
                              v-model="form.is_pharmacy"
                              name="pharmacy"
                              :value="1"
                            >Nhà thuốc</b-form-radio>
                            <b-form-radio v-model="form.is_pharmacy" name="someone" :value="2">Khác</b-form-radio>
                          </div>
                          <!-- <b-form-input type="number" v-model="form.is_pharmacy" /> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-card-actions>
          </div>
        </div>

        <div class="row match-height">
          <div class="col-lg-6">
            <b-card-actions class="margin-none" no-body title="Thông tin thầu" action-collapse>
              <b-card class="mb-0" style="min-height:280px" bg-variant="light">
                <b-form>
                  <b-row class="mt-1">
                    <b-col md="3">
                      <b-form-group label="Mã đơn vị BHQĐ" class="mb-2">
                        <validation-provider #default="{ errors }" name="Mã đơn vị BHQĐ">
                          <b-form-input v-model="form.issuer_code" />
                          <!-- <b-form-select v-model="form.issuer_code" :options="[]"></b-form-select> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường medicine_type -->
                    <b-col md="3">
                      <b-form-group label="Loại thuốc (theo QĐ3618)" class="mb-2">
                        <validation-provider #default="{ errors }" name="Loại thuốc (theo QĐ3618)">
                          <v-select
                            class="cs-vSelect"
                            :options="medicineCategoryOptions.options"
                            :filterable="false"
                            @open="handleGetMedicineCateOptions"
                            v-model="form.medicine_type"
                            transition
                            label="name"
                            :noDrop="!medicineCategoryOptions.options.length"
                            :loading="medicineCategoryOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetMedicineCateOptions,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường bidding_type -->
                    <b-col md="3">
                      <b-form-group label="Loại thầu" class="mb-2">
                        <validation-provider #default="{ errors }" name="Loại thầu">
                          <v-select
                            class="cs-vSelect cs-over"
                            :options="biddingTypesOptions.options"
                            :filterable="false"
                            @open="handleGetBiddingTypesOptions"
                            v-model="form.bidding_type"
                            transition
                            label="name"
                            :noDrop="!biddingTypesOptions.options.length"
                            :loading="biddingTypesOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetBiddingTypesOptions,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường bidding_form -->
                    <b-col md="3">
                      <b-form-group label="Hình thức thầu" class="mb-2">
                        <validation-provider #default="{ errors }" name="Hình thức thầu">
                          <v-select
                            class="cs-vSelect"
                            :options="biddingFormsOptions.options"
                            :filterable="false"
                            @open="handleGetBiddingFormsOptions"
                            v-model="form.bidding_form"
                            transition
                            label="name"
                            :noDrop="!biddingFormsOptions.options.length"
                            :loading="biddingFormsOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetBiddingFormsOptions,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Số QĐ trúng thầu" class="mb-2">
                        <validation-provider #default="{ errors }" name="Số QĐ trúng thầu">
                          <b-form-input v-model="form.bid_win_number" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường bid_package -->
                    <b-col md="3">
                      <b-form-group label="Gói thầu" class="mb-2">
                        <validation-provider #default="{ errors }" name="Gói thầu">
                          <b-form-input v-model="form.bid_package" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường bid_group -->
                    <b-col md="3">
                      <b-form-group label="Nhóm thầu" class="mb-2">
                        <validation-provider #default="{ errors }" name="Nhóm thầu">
                          <b-form-input v-model="form.bid_group" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường bid_year -->
                    <b-col md="3">
                      <b-form-group label="Năm thầu" class="mb-2">
                        <validation-provider #default="{ errors }" name="Năm thầu">
                          <b-form-input v-model="form.bid_year" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường bid_order_no -->
                    <b-col md="3">
                      <b-form-group label="Số thứ tự thầu" class="mb-2">
                        <validation-provider #default="{ errors }" name="Số thứ tự thầu">
                          <b-form-input v-model="form.bid_order_no" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-card-actions>
          </div>
          <div class="col-lg-6">
            <b-card-actions class="margin-none" no-body title="Bào chế" action-collapse>
              <b-card class="mb-0" style="min-height:280px" bg-variant="light">
                <b-form class="mt-1">
                  <b-row>
                    <b-col md="4">
                      <b-form-group label="Dạng bào chế" class="mb-2">
                        <validation-provider #default="{ errors }" name="Dạng bào chế">
                          <b-form-input v-model="form.dosage_form_2" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường issuer_code -->

                    <!-- Trường dosage_form -->
                    <b-col md="4">
                      <b-form-group label="Dạng bào chế(theo QĐ3618)" class="mb-2">
                        <validation-provider #default="{ errors }" name="Dạng bào chế(theo QĐ3618)">
                          <b-form-input v-model="form.dosage_form" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường Reconstitution -->
                    <b-col md="4">
                      <b-form-group label="Hoàn nguyên" class="mb-2">
                        <validation-provider #default="{ errors }" name="Hoàn nguyên">
                          <!-- <b-form-select
                            v-model="form.reconstitution"
                            :options="optionsData.RECONSTITUTION"
                          ></b-form-select>-->
                          <v-select
                            class="cs-vSelect cs-dilution"
                            :options="reconstitutionOptions.options"
                            :filterable="false"
                            @open="handleGetReconstitutionOptions"
                            v-model="form.reconstitution"
                            transition
                            label="name"
                            :noDrop="!reconstitutionOptions.options.length"
                            :loading="reconstitutionOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetReconstitutionOptions,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường Reconstitution_V -->
                    <b-col md="4">
                      <b-form-group label="Thể tích hoàn nguyên" class="mb-2">
                        <validation-provider #default="{ errors }" name="Thể tích hoàn nguyên">
                          <b-form-input v-model="form.reconstitution_v" />
                          <!-- <b-form-select :options="[]"></b-form-select> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường Dilution -->
                    <b-col md="4">
                      <b-form-group label="Dung môi pha thuốc" class="mb-2">
                        <validation-provider #default="{ errors }" name="Dung môi pha thuốc">
                          <v-select
                            class="cs-vSelect"
                            :options="dilutionOptions.options"
                            :filterable="false"
                            @open="handleGetDilutionOptions"
                            v-model="form.dilution"
                            transition
                            label="name"
                            :noDrop="!dilutionOptions.options.length"
                            :loading="dilutionOptions.isLoading"
                            @search="(value)=>handleDebounce(handleGetDilutionOptions,value)"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="18" />
                              </span>
                            </template>
                          </v-select>
                          <!-- <b-form-select v-model="form.dilution" :options="optionsData.DILUTION"></b-form-select> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường Dilution_V -->
                    <b-col md="4">
                      <b-form-group label="Thể tích pha thuốc" class="mb-2">
                        <validation-provider #default="{ errors }" name="Thể tích pha thuốc">
                          <b-form-input v-model="form.dilution_v" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường Dilution_concentration_max -->
                    <b-col md="4">
                      <b-form-group label="Nồng độ pha thuốc tối đa" class="mb-2">
                        <validation-provider #default="{ errors }" name="Nồng độ pha thuốc tối đa">
                          <b-form-input v-model="form.dilution_concentration_max" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Trường Dilution_concentration_min -->
                    <b-col md="4">
                      <b-form-group label="Nồng độ pha thuốc tối thiểu" class="mb-2">
                        <validation-provider
                          #default="{ errors }"
                          name="Nồng độ pha thuốc tối thiểu"
                        >
                          <b-form-input v-model="form.dilution_concentration_min" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-card-actions>
          </div>
        </div>
        <b-row>
          <b-col>
            <b-card-actions
              class="margin-none"
              no-body
              title="Hướng dẫn sử dụng thuốc"
              action-collapse
            >
              <b-card class="mb-0" bg-variant="light">
                <b-form-group label class="mt-3">
                  <validation-provider #default="{ errors }" name="Hướng dẫn sử dụng thuốc">
                    <b-form-input
                      style="height:45px"
                      v-model="form.guide_line"
                      placeholder="Nhập hướng dẫn sử dụng thuốc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card>
            </b-card-actions>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </validation-observer>
    <div class="d-flex align-items-center justify-content-end mt-2">
      <b-button
        :disabled="isLoading"
        @click="handleCreateMedicine"
        variant="primary"
      >{{isUpdate?"Cập nhật":"Tạo mới"}}</b-button>
    </div>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BCardBody,
  BFormRadio,
  BMediaBody,
  BFormFile,
  BMedia,
  BMediaAside,
  BImg,
  BLink,
  BCardText,
  BFormSelect
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import addProducts from "./addProduct";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Cleave from "vue-cleave-component";
import * as optionsData from "@/utils/optionsData";
import appUtils from "@/utils/appUtils";
import { required } from "@validations";

export default {
  name: "ProductManager",
  components: {
    vSelect,
    BFormRadio,
    BCardBody,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BMediaBody,
    BFormFile,
    BMedia,
    BMediaAside,
    BImg,
    BLink,
    BCardText,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BCardActions,
    BFormSelect,
    Cleave
  },
  directives: {
    Ripple
  },
  data() {
    return {
      optionsData,
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand"
        }
      },
      pharmacyOptions: [],

      isUpdate: false,
      required,
      unitOptions: {
        options: [],
        isLoading: false
      },
      routeOfUseOptions: {
        options: [],
        isLoading: false
      },
      countryOptions: {
        options: [],
        isLoading: false
      },
      manufacturerOptions: {
        options: [],
        isLoading: false
      },
      supplierOptions: {
        options: [],
        isLoading: false
      },
      medicineCategoryOptions: {
        options: [],
        isLoading: false
      },
      biddingTypesOptions: {
        options: [],
        isLoading: false
      },
      biddingFormsOptions: {
        options: [],
        isLoading: false
      },
      reconstitutionOptions: {
        options: [],
        isLoading: false
      },
      dilutionOptions: {
        options: [],
        isLoading: false
      },
      productTypeOptions: {
        options: [],
        isLoading: false
      },
      healthCostOptions: {
        options: [],
        isLoading: false
      },
      groupMgntOptions: {
        options: [],
        isLoading: false
      },
      groupResearchers: {
        options: [],
        isLoading: false
      },
      groupPathologicals: {
        options: [],
        isLoading: false
      },
      groupABCVEN: {
        options: [],
        isLoading: false
      },
      groupPharmacologicals: {
        options: [],
        isLoading: false
      },
      groupSubPharmacologicals: {
        options: [],
        isLoading: false
      },
      activeIngredientOptions: {
        options: [],
        isLoading: false
      }
    };
  },
  computed: {
    organizations() {
      const userLocal = JSON.parse(localStorage.getItem("user"));
      return (
        userLocal?.members
          ?.filter(itemFilter => [1, 2].includes(itemFilter?.role))
          ?.map(item => item?.org) || []
      );
    }
  },
  setup() {
    const mixData = addProducts();

    return {
      ...mixData
    };
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.handleGetMedicineInfo();
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.handleGetMedicineInfo();
    }
  },
  methods: {
    handleCreateMedicine() {
      this.$refs.addProductValidation.validate().then(success => {
        if (success) {
          this.isUpdate
            ? this.handleUpdateMedinice()
            : this.addPharmaProduct(this.form);
        }
      });
    },
    handleUpdateMedinice() {
      this.updateMedicine(this.$route.params.id, this.form);
    },
    async handleGetMedicineInfo() {
      this.isUpdate = true;
      this.getMedicineDetail(this.$route.params.id);
    },
    async handleGetUnitOptions(value) {
      if (typeof value === "undefined" && this.unitOptions.options?.length)
        return;

      try {
        this.unitOptions.isLoading = true;
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 1000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getUnitTypes",
          params
        );
        this.unitOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.unitOptions.isLoading = false;
      }
    },
    handleDebounce: appUtils.debounce(function(callback, value) {
      callback(value);
    }, 1000),
    async handleGetRouteOfUseOptions(value) {
      if (
        typeof value === "undefined" &&
        this.routeOfUseOptions.options?.length
      )
        return;
      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getUseRoute",
          params
        );
        this.routeOfUseOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.routeOfUseOptions.isLoading = false;
      }
    },
    async handleGetCountryOptions(value) {
      if (typeof value === "undefined" && this.countryOptions.options?.length)
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getCountries",
          params
        );
        this.countryOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.countryOptions.isLoading = false;
      }
    },
    async handleGetManufacturersOptions(value) {
      if (
        typeof value === "undefined" &&
        this.manufacturerOptions.options?.length
      )
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getManufacturers",
          params
        );
        this.manufacturerOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.manufacturerOptions.isLoading = false;
      }
    },
    async handleGetSuppliersOptions(value) {
      if (typeof value === "undefined" && this.supplierOptions.options?.length)
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getSuppliers",
          params
        );
        this.supplierOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.supplierOptions.isLoading = false;
      }
    },
    async handleGetMedicineCateOptions(value) {
      if (
        typeof value === "undefined" &&
        this.medicineCategoryOptions.options?.length
      )
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getMedicineCategories",
          params
        );
        this.medicineCategoryOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.medicineCategoryOptions.isLoading = false;
      }
    },
    async handleGetBiddingTypesOptions(value) {
      if (
        typeof value === "undefined" &&
        this.biddingTypesOptions.options?.length
      )
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getBiddingTypes",
          params
        );
        this.biddingTypesOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.biddingTypesOptions.isLoading = false;
      }
    },
    async handleGetBiddingFormsOptions(value) {
      if (
        typeof value === "undefined" &&
        this.biddingFormsOptions.options?.length
      )
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getBiddingForms",
          params
        );
        this.biddingFormsOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.biddingFormsOptions.isLoading = false;
      }
    },
    async handleGetReconstitutionOptions(value) {
      if (
        typeof value === "undefined" &&
        this.reconstitutionOptions.options?.length
      )
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getReconstitutions",
          params
        );
        this.reconstitutionOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.reconstitutionOptions.isLoading = false;
      }
    },
    async handleGetDilutionOptions(value) {
      if (typeof value === "undefined" && this.dilutionOptions.options?.length)
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getDilutions",
          params
        );
        this.dilutionOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.dilutionOptions.isLoading = false;
      }
    },
    async handleGetProductCateOptions(value) {
      if (
        typeof value === "undefined" &&
        this.productTypeOptions.options?.length
      )
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getProductCategories",
          params
        );
        this.productTypeOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.productTypeOptions.isLoading = false;
      }
    },
    async handleGetGroupManagers(value) {
      if (typeof value === "undefined" && this.groupMgntOptions.options?.length)
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getGroupManagers",
          params
        );
        this.groupMgntOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.groupMgntOptions.isLoading = false;
      }
    },
    async haddleGetGroupResearchers(value) {
      if (typeof value === "undefined" && this.groupResearchers.options?.length)
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getGroupResearchers",
          params
        );
        this.groupResearchers.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.groupResearchers.isLoading = false;
      }
    },
    async handleGetGroupPathologicals(value) {
      if (
        typeof value === "undefined" &&
        this.groupPathologicals.options?.length
      )
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getGroupPathologicals",
          params
        );
        this.groupPathologicals.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.groupPathologicals.isLoading = false;
      }
    },
    async handleGetGroupABCVEN(value) {
      if (typeof value === "undefined" && this.groupABCVEN.options?.length)
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getGroupABCVEN",
          params
        );
        this.groupABCVEN.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.groupABCVEN.isLoading = false;
      }
    },
    async handleGetGroupPharmacologicals(value) {
      if (
        typeof value === "undefined" &&
        this.groupPharmacologicals.options?.length
      )
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getGroupPharmacologicals",
          params
        );
        this.groupPharmacologicals.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.groupPharmacologicals.isLoading = false;
      }
    },
    // async handleGetGroupSubPharmacologicals(value) {
    //   if (
    //     typeof value === "undefined" &&
    //     this.groupSubPharmacologicals.options?.length
    //   )
    //     return;

    //   try {
    //     const params = {
    //       keyword: value,
    //       page_num: 1,
    //       page_size: 10000,
    //       sort_by: "id",
    //       order: "desc"
    //     };

    //     const response = await this.$store.dispatch(
    //       "phamarProduct/getGroupSubPharmacologicals",
    //       params
    //     );
    //     this.groupSubPharmacologicals.options = response.data.data || [];
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     this.groupSubPharmacologicals.isLoading = false;
    //   }
    // },
    handleGetGroupSubPharmacologicals(value) {
      this.groupSubPharmacologicals.options =
        this.groupSubPharmacologicals.options.filter(item =>
          item?.name?.includes(value)
        ) || [];
    },
    async handleGetHealthCost(value) {
      if (
        typeof value === "undefined" &&
        this.healthCostOptions.options?.length
      )
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getHealthCost",
          params
        );
        this.healthCostOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.healthCostOptions.isLoading = false;
      }
    },
    async handleGetActiveIngredientOptions(value) {
      if (
        typeof value === "undefined" &&
        this.activeIngredientOptions.options?.length
      )
        return;

      try {
        const params = {
          keyword: value,
          page_num: 1,
          page_size: 10000,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getActiveIngredients",
          params
        );
        this.activeIngredientOptions.options = response.data.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.activeIngredientOptions.isLoading = false;
      }
    },
    handlSelectedPharmacological(value) {
      this.groupSubPharmacologicals.options =
        value?.group_sub_pharmalogical || [];
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';
@import "~swiper/css/swiper.css";
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
  display: none;
}

.product-add-wrapper {
  .card-body {
    padding-top: 0;
  }
}

.margin-none > div {
  margin: 0;
}

.cs-card {
  min-height: 420px;
}

.cs-vSelect {
  background-color: white;
}

.cs-vSelect .vs__dropdown-menu {
  max-height: 180px !important;
  min-width: 300px !important;
  width: 100% !important;

  .vs__dropdown-option {
    white-space: normal;
  }
}

.cs-dilution .vs__dropdown-menu {
  left: -145px !important;
}

.cs-vSelect .vs__selected {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // default 1 line
  -webkit-box-orient: vertical;
  padding: 0;
  margin: 0;
  max-width: 90px;
}

.cs-vSelect-large .vs__selected {
  max-width: unset;
}

.cs-vSelect .vs__selected-options {
  flex-wrap: nowrap !important;
}

.cs-vSelect .vs__search {
  flex: 1;
}
</style>
