var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"product-add-wrapper"},[_c('validation-observer',{ref:"addProductValidation"},[_c('b-form',[_c('div',{staticClass:"row match-height"},[_c('div',{staticClass:"col-lg-6"},[_c('b-card-actions',{staticClass:"margin-none",attrs:{"no-body":"","title":"Thông tin thuốc","action-collapse":""}},[_c('b-card',{staticClass:"mb-0 cs-card",attrs:{"bg-variant":"light"}},[_c('b-form',[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"rules":"required","name":"Mã thuốc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Mã thuốc "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{model:{value:(_vm.form.product_code),callback:function ($$v) {_vm.$set(_vm.form, "product_code", $$v)},expression:"form.product_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?"Mã thuốc không được để trống":''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Mã thuốc BHYT"}},[_c('validation-provider',{attrs:{"name":"Mã thuốc BHYT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.product_hi_code),callback:function ($$v) {_vm.$set(_vm.form, "product_hi_code", $$v)},expression:"form.product_hi_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Tên thuốc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Tên thuốc "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Tên thuốc không được để trống':''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"rules":"required","name":"Đơn vị"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Đơn vị "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.unitOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.unitOptions.options.length,"loading":_vm.unitOptions.isLoading,"selectOnTab":""},on:{"open":_vm.handleGetUnitOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetUnitOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.unit_id),callback:function ($$v) {_vm.$set(_vm.form, "unit_id", $$v)},expression:"form.unit_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Quy cách không được để trống':''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Quy cách đóng gói"}},[_c('validation-provider',{attrs:{"name":"Quy cách đóng gói"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.packing),callback:function ($$v) {_vm.$set(_vm.form, "packing", $$v)},expression:"form.packing"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Hoạt chất","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Hoạt chất "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.activeIngredientOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.activeIngredientOptions.options.length,"loading":_vm.activeIngredientOptions.isLoading},on:{"open":_vm.handleGetActiveIngredientOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetActiveIngredientOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.active_ingredient),callback:function ($$v) {_vm.$set(_vm.form, "active_ingredient", $$v)},expression:"form.active_ingredient"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Hoạt chất không được để trống':''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Hàm lượng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v("Hàm lượng")]),_c('b-form-input',{model:{value:(_vm.form.quality),callback:function ($$v) {_vm.$set(_vm.form, "quality", $$v)},expression:"form.quality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Hàm lượng không được để trống':''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Nồng độ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v("Nồng độ")]),_c('b-form-input',{model:{value:(_vm.form.concentration),callback:function ($$v) {_vm.$set(_vm.form, "concentration", $$v)},expression:"form.concentration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Nồng độ không được để trống':''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Thể tích"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v("Thể tích")]),_c('b-form-input',{model:{value:(_vm.form.volume),callback:function ($$v) {_vm.$set(_vm.form, "volume", $$v)},expression:"form.volume"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"rules":"required","name":"Đường dùng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Đường dùng "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.routeOfUseOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.routeOfUseOptions.options.length,"loading":_vm.routeOfUseOptions.isLoading},on:{"open":_vm.handleGetRouteOfUseOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetRouteOfUseOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.route_of_use),callback:function ($$v) {_vm.$set(_vm.form, "route_of_use", $$v)},expression:"form.route_of_use"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Đường dùng không được để trống':''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nước sản xuất"}},[_c('validation-provider',{attrs:{"name":"Nước sản xuất"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.countryOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.countryOptions.options.length,"loading":_vm.countryOptions.isLoading},on:{"open":_vm.handleGetCountryOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetCountryOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Hãng sản xuất"}},[_c('validation-provider',{attrs:{"name":"Hãng sản xuất"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect cs-vSelect-large",attrs:{"options":_vm.manufacturerOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.manufacturerOptions.options.length,"loading":_vm.manufacturerOptions.isLoading},on:{"open":_vm.handleGetManufacturersOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetManufacturersOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.manufacturer),callback:function ($$v) {_vm.$set(_vm.form, "manufacturer", $$v)},expression:"form.manufacturer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"rules":"required","name":"Nhà cung cấp"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Nhà cung cấp "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{staticClass:"cs-vSelect cs-vSelect-large",attrs:{"options":_vm.supplierOptions.options,"filterable":false,"transition":"","label":"supplier_name","noDrop":!_vm.supplierOptions.options.length,"loading":_vm.supplierOptions.isLoading},on:{"open":_vm.handleGetSuppliersOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetSuppliersOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.supplier),callback:function ($$v) {_vm.$set(_vm.form, "supplier", $$v)},expression:"form.supplier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Nhà cung cấp không được để trống':''))])]}}])})],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('b-card-actions',{staticClass:"margin-none",attrs:{"no-body":"","title":"Thông tin quản lý thuốc","action-collapse":""}},[_c('b-card',{staticClass:"mb-0 cs-card",staticStyle:{"min-height":"470px"},attrs:{"bg-variant":"light"}},[_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Số lô"}},[_c('validation-provider',{attrs:{"name":"Số lô"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.form.lot_number),callback:function ($$v) {_vm.$set(_vm.form, "lot_number", $$v)},expression:"form.lot_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Số đăng ký"}},[_c('validation-provider',{attrs:{"name":"Số đăng ký"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.registration_number),callback:function ($$v) {_vm.$set(_vm.form, "registration_number", $$v)},expression:"form.registration_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Mã ATC"}},[_c('validation-provider',{attrs:{"name":"Mã ATC"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.atc_code),callback:function ($$v) {_vm.$set(_vm.form, "atc_code", $$v)},expression:"form.atc_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Mã dược quốc gia"}},[_c('validation-provider',{attrs:{"name":"Mã dược quốc gia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.national_pharmacy_code),callback:function ($$v) {_vm.$set(_vm.form, "national_pharmacy_code", $$v)},expression:"form.national_pharmacy_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Hạn sử dụng_ngày"}},[_c('validation-provider',{attrs:{"name":"Hạn sử dụng_ngày"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.expiry_date_day),callback:function ($$v) {_vm.$set(_vm.form, "expiry_date_day", $$v)},expression:"form.expiry_date_day"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Hạn sử dụng_tháng"}},[_c('validation-provider',{attrs:{"name":"Hạn sử dụng_tháng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.expiry_date_month),callback:function ($$v) {_vm.$set(_vm.form, "expiry_date_month", $$v)},expression:"form.expiry_date_month"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Hạn sử dụng_năm"}},[_c('validation-provider',{attrs:{"name":"Hạn sử dụng_năm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.expiry_date_year),callback:function ($$v) {_vm.$set(_vm.form, "expiry_date_year", $$v)},expression:"form.expiry_date_year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Cảnh báo hạn sử dụng"}},[_c('validation-provider',{attrs:{"name":"Cảnh báo hạn sử dụng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.expiration_warning),callback:function ($$v) {_vm.$set(_vm.form, "expiration_warning", $$v)},expression:"form.expiration_warning"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Cảnh báo tồn kho"}},[_c('validation-provider',{attrs:{"name":"Cảnh báo tồn kho"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.inventory_warning),callback:function ($$v) {_vm.$set(_vm.form, "inventory_warning", $$v)},expression:"form.inventory_warning"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)]),_c('div',{staticClass:"row match-height"},[_c('div',{staticClass:"col-lg-6"},[_c('b-card-actions',{staticClass:"margin-none",attrs:{"no-body":"","title":"Loại thuốc và Chi phí","action-collapse":""}},[_c('b-card',{staticClass:"mb-0",staticStyle:{"min-height":"380px"},attrs:{"bg-variant":"light"}},[_c('b-form',[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Loại thuốc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Loại thuốc "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.productTypeOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.productTypeOptions.options.length,"loading":_vm.productTypeOptions.isLoading},on:{"open":_vm.handleGetProductCateOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetProductCateOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.pharma_product_type_id),callback:function ($$v) {_vm.$set(_vm.form, "pharma_product_type_id", $$v)},expression:"form.pharma_product_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?"Loại thuốc không được để trống":''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nhóm chi phí BHYT"}},[_c('validation-provider',{attrs:{"name":"Nhóm chi phí BHYT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.healthCostOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.healthCostOptions.options.length,"loading":_vm.healthCostOptions.isLoading},on:{"open":_vm.handleGetHealthCost,"search":function (value){ return _vm.handleDebounce(_vm.handleGetHealthCost,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.insurance_cost_group),callback:function ($$v) {_vm.$set(_vm.form, "insurance_cost_group", $$v)},expression:"form.insurance_cost_group"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"rules":"required","name":"Giá nhập"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Giá nhập "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{attrs:{"append":"VNĐ"}},[_c('Cleave',{staticClass:"form-control",attrs:{"id":"input_price_in","raw":false,"options":_vm.cleaveOptions.number,"placeholder":"10,000"},model:{value:(_vm.form.price_in),callback:function ($$v) {_vm.$set(_vm.form, "price_in", $$v)},expression:"form.price_in"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Giá nhập không được để trống':''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"VAT nhập(%)"}},[_c('validation-provider',{attrs:{"name":"VAT nhập"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.vat_in),callback:function ($$v) {_vm.$set(_vm.form, "vat_in", $$v)},expression:"form.vat_in"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"rules":"required","name":"Giá bán"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Giá bán "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{attrs:{"append":"VNĐ"}},[_c('Cleave',{staticClass:"form-control",attrs:{"id":"input_price_out","raw":false,"options":_vm.cleaveOptions.number,"placeholder":"10,000"},model:{value:(_vm.form.price_out),callback:function ($$v) {_vm.$set(_vm.form, "price_out", $$v)},expression:"form.price_out"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Giá bán không được để trống':''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"VAT bán(%)"}},[_c('validation-provider',{attrs:{"name":"VAT bán"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.vat_out),callback:function ($$v) {_vm.$set(_vm.form, "vat_out", $$v)},expression:"form.vat_out"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Giá BHYT"}},[_c('validation-provider',{attrs:{"name":"Giá BHYT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"VNĐ"}},[_c('Cleave',{staticClass:"form-control",attrs:{"id":"price_health_insurance","raw":false,"options":_vm.cleaveOptions.number,"placeholder":"10,000"},model:{value:(_vm.form.price_health_insurance),callback:function ($$v) {_vm.$set(_vm.form, "price_health_insurance", $$v)},expression:"form.price_health_insurance"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Giá viện phí"}},[_c('validation-provider',{attrs:{"name":"Giá viện phí"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"VNĐ"}},[_c('Cleave',{staticClass:"form-control",attrs:{"id":"price_hospital_fee","raw":false,"options":_vm.cleaveOptions.number,"placeholder":"10,000"},model:{value:(_vm.form.price_hospital_fee),callback:function ($$v) {_vm.$set(_vm.form, "price_hospital_fee", $$v)},expression:"form.price_hospital_fee"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Giá dịch vụ"}},[_c('validation-provider',{attrs:{"name":"Giá dịch vụ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"VNĐ"}},[_c('Cleave',{staticClass:"form-control",attrs:{"id":"price_service","raw":false,"options":_vm.cleaveOptions.number,"placeholder":"10,000"},model:{value:(_vm.form.price_service),callback:function ($$v) {_vm.$set(_vm.form, "price_service", $$v)},expression:"form.price_service"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"% đúng tuyến BHYT"}},[_c('validation-provider',{attrs:{"name":"% đúng tuyến BHYT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.percent_insurance_portability),callback:function ($$v) {_vm.$set(_vm.form, "percent_insurance_portability", $$v)},expression:"form.percent_insurance_portability"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"% trái tuyến BHYT"}},[_c('validation-provider',{attrs:{"name":"% trái tuyến BHYT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.percent_insurance_uninsured),callback:function ($$v) {_vm.$set(_vm.form, "percent_insurance_uninsured", $$v)},expression:"form.percent_insurance_uninsured"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('b-card-actions',{staticClass:"margin-none",attrs:{"no-body":"","title":"Nhóm thuốc","action-collapse":""}},[_c('b-card',{staticClass:"mb-0 cs-card",staticStyle:{"min-height":"380px"},attrs:{"bg-variant":"light"}},[_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nhóm quản lý"}},[_c('validation-provider',{attrs:{"name":"Nhóm quản lý"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.groupMgntOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.groupMgntOptions.options.length,"loading":_vm.groupMgntOptions.isLoading},on:{"open":_vm.handleGetGroupManagers,"search":function (value){ return _vm.handleDebounce(_vm.handleGetGroupManagers,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.group_mgnt),callback:function ($$v) {_vm.$set(_vm.form, "group_mgnt", $$v)},expression:"form.group_mgnt"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nhóm nghiên cứu"}},[_c('validation-provider',{attrs:{"name":"Nhóm nghiên cứu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.groupResearchers.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.groupResearchers.options.length,"loading":_vm.groupResearchers.isLoading},on:{"open":_vm.haddleGetGroupResearchers,"search":function (value){ return _vm.handleDebounce(_vm.haddleGetGroupResearchers,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.group_research),callback:function ($$v) {_vm.$set(_vm.form, "group_research", $$v)},expression:"form.group_research"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nhóm bệnh lý"}},[_c('validation-provider',{attrs:{"name":"Nhóm bệnh lý"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect cs-dilution",attrs:{"options":_vm.groupPathologicals.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.groupPathologicals.options.length,"loading":_vm.groupPathologicals.isLoading},on:{"open":_vm.handleGetGroupPathologicals,"search":function (value){ return _vm.handleDebounce(_vm.handleGetGroupPathologicals,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.group_pathological),callback:function ($$v) {_vm.$set(_vm.form, "group_pathological", $$v)},expression:"form.group_pathological"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nhóm ABC/VEN"}},[_c('validation-provider',{attrs:{"name":"Nhóm ABC/VEN"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.groupABCVEN.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.groupABCVEN.options.length,"loading":_vm.groupABCVEN.isLoading},on:{"open":_vm.handleGetGroupABCVEN,"search":function (value){ return _vm.handleDebounce(_vm.handleGetGroupABCVEN,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.group_abcven),callback:function ($$v) {_vm.$set(_vm.form, "group_abcven", $$v)},expression:"form.group_abcven"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nhóm dược lý"}},[_c('validation-provider',{attrs:{"name":"Nhóm dược lý"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.groupPharmacologicals.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.groupPharmacologicals.options.length,"loading":_vm.groupPharmacologicals.isLoading},on:{"open":_vm.handleGetGroupPharmacologicals,"input":_vm.handlSelectedPharmacological,"search":function (value){ return _vm.handleDebounce(_vm.handleGetGroupPharmacologicals,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.group_pharmacological),callback:function ($$v) {_vm.$set(_vm.form, "group_pharmacological", $$v)},expression:"form.group_pharmacological"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nhóm tiểu dược lý"}},[_c('validation-provider',{attrs:{"name":"Nhóm tiểu dược lý"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect cs-dilution",attrs:{"options":_vm.groupSubPharmacologicals.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.groupSubPharmacologicals.options.length,"loading":_vm.groupSubPharmacologicals.isLoading},on:{"search":function (value){ return _vm.handleDebounce(_vm.handleGetGroupSubPharmacologicals,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.group_sub_pharmacological),callback:function ($$v) {_vm.$set(_vm.form, "group_sub_pharmacological", $$v)},expression:"form.group_sub_pharmacological"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Trạng thái khóa"}},[_c('validation-provider',{attrs:{"name":"Trạng thái khóa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"12px","margin-top":"10px"}},[_c('b-form-radio',{attrs:{"name":"unlock","value":1},model:{value:(_vm.form.is_lock),callback:function ($$v) {_vm.$set(_vm.form, "is_lock", $$v)},expression:"form.is_lock"}},[_vm._v("Không khoá")]),_c('b-form-radio',{attrs:{"name":"lock","value":2},model:{value:(_vm.form.is_lock),callback:function ($$v) {_vm.$set(_vm.form, "is_lock", $$v)},expression:"form.is_lock"}},[_vm._v("Khoá")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Thuốc nhà thuốc"}},[_c('validation-provider',{attrs:{"name":"Thuốc nhà thuốc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"12px","margin-top":"10px"}},[_c('b-form-radio',{attrs:{"name":"pharmacy","value":1},model:{value:(_vm.form.is_pharmacy),callback:function ($$v) {_vm.$set(_vm.form, "is_pharmacy", $$v)},expression:"form.is_pharmacy"}},[_vm._v("Nhà thuốc")]),_c('b-form-radio',{attrs:{"name":"someone","value":2},model:{value:(_vm.form.is_pharmacy),callback:function ($$v) {_vm.$set(_vm.form, "is_pharmacy", $$v)},expression:"form.is_pharmacy"}},[_vm._v("Khác")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)]),_c('div',{staticClass:"row match-height"},[_c('div',{staticClass:"col-lg-6"},[_c('b-card-actions',{staticClass:"margin-none",attrs:{"no-body":"","title":"Thông tin thầu","action-collapse":""}},[_c('b-card',{staticClass:"mb-0",staticStyle:{"min-height":"280px"},attrs:{"bg-variant":"light"}},[_c('b-form',[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Mã đơn vị BHQĐ"}},[_c('validation-provider',{attrs:{"name":"Mã đơn vị BHQĐ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.issuer_code),callback:function ($$v) {_vm.$set(_vm.form, "issuer_code", $$v)},expression:"form.issuer_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Loại thuốc (theo QĐ3618)"}},[_c('validation-provider',{attrs:{"name":"Loại thuốc (theo QĐ3618)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.medicineCategoryOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.medicineCategoryOptions.options.length,"loading":_vm.medicineCategoryOptions.isLoading},on:{"open":_vm.handleGetMedicineCateOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetMedicineCateOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.medicine_type),callback:function ($$v) {_vm.$set(_vm.form, "medicine_type", $$v)},expression:"form.medicine_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Loại thầu"}},[_c('validation-provider',{attrs:{"name":"Loại thầu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect cs-over",attrs:{"options":_vm.biddingTypesOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.biddingTypesOptions.options.length,"loading":_vm.biddingTypesOptions.isLoading},on:{"open":_vm.handleGetBiddingTypesOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetBiddingTypesOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.bidding_type),callback:function ($$v) {_vm.$set(_vm.form, "bidding_type", $$v)},expression:"form.bidding_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Hình thức thầu"}},[_c('validation-provider',{attrs:{"name":"Hình thức thầu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.biddingFormsOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.biddingFormsOptions.options.length,"loading":_vm.biddingFormsOptions.isLoading},on:{"open":_vm.handleGetBiddingFormsOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetBiddingFormsOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.bidding_form),callback:function ($$v) {_vm.$set(_vm.form, "bidding_form", $$v)},expression:"form.bidding_form"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Số QĐ trúng thầu"}},[_c('validation-provider',{attrs:{"name":"Số QĐ trúng thầu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.bid_win_number),callback:function ($$v) {_vm.$set(_vm.form, "bid_win_number", $$v)},expression:"form.bid_win_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Gói thầu"}},[_c('validation-provider',{attrs:{"name":"Gói thầu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.bid_package),callback:function ($$v) {_vm.$set(_vm.form, "bid_package", $$v)},expression:"form.bid_package"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nhóm thầu"}},[_c('validation-provider',{attrs:{"name":"Nhóm thầu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.bid_group),callback:function ($$v) {_vm.$set(_vm.form, "bid_group", $$v)},expression:"form.bid_group"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Năm thầu"}},[_c('validation-provider',{attrs:{"name":"Năm thầu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.bid_year),callback:function ($$v) {_vm.$set(_vm.form, "bid_year", $$v)},expression:"form.bid_year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Số thứ tự thầu"}},[_c('validation-provider',{attrs:{"name":"Số thứ tự thầu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.bid_order_no),callback:function ($$v) {_vm.$set(_vm.form, "bid_order_no", $$v)},expression:"form.bid_order_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('b-card-actions',{staticClass:"margin-none",attrs:{"no-body":"","title":"Bào chế","action-collapse":""}},[_c('b-card',{staticClass:"mb-0",staticStyle:{"min-height":"280px"},attrs:{"bg-variant":"light"}},[_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Dạng bào chế"}},[_c('validation-provider',{attrs:{"name":"Dạng bào chế"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.dosage_form_2),callback:function ($$v) {_vm.$set(_vm.form, "dosage_form_2", $$v)},expression:"form.dosage_form_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Dạng bào chế(theo QĐ3618)"}},[_c('validation-provider',{attrs:{"name":"Dạng bào chế(theo QĐ3618)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.dosage_form),callback:function ($$v) {_vm.$set(_vm.form, "dosage_form", $$v)},expression:"form.dosage_form"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Hoàn nguyên"}},[_c('validation-provider',{attrs:{"name":"Hoàn nguyên"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect cs-dilution",attrs:{"options":_vm.reconstitutionOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.reconstitutionOptions.options.length,"loading":_vm.reconstitutionOptions.isLoading},on:{"open":_vm.handleGetReconstitutionOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetReconstitutionOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.reconstitution),callback:function ($$v) {_vm.$set(_vm.form, "reconstitution", $$v)},expression:"form.reconstitution"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Thể tích hoàn nguyên"}},[_c('validation-provider',{attrs:{"name":"Thể tích hoàn nguyên"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.reconstitution_v),callback:function ($$v) {_vm.$set(_vm.form, "reconstitution_v", $$v)},expression:"form.reconstitution_v"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Dung môi pha thuốc"}},[_c('validation-provider',{attrs:{"name":"Dung môi pha thuốc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"cs-vSelect",attrs:{"options":_vm.dilutionOptions.options,"filterable":false,"transition":"","label":"name","noDrop":!_vm.dilutionOptions.options.length,"loading":_vm.dilutionOptions.isLoading},on:{"open":_vm.handleGetDilutionOptions,"search":function (value){ return _vm.handleDebounce(_vm.handleGetDilutionOptions,value); }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}}],null,true),model:{value:(_vm.form.dilution),callback:function ($$v) {_vm.$set(_vm.form, "dilution", $$v)},expression:"form.dilution"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Thể tích pha thuốc"}},[_c('validation-provider',{attrs:{"name":"Thể tích pha thuốc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.dilution_v),callback:function ($$v) {_vm.$set(_vm.form, "dilution_v", $$v)},expression:"form.dilution_v"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nồng độ pha thuốc tối đa"}},[_c('validation-provider',{attrs:{"name":"Nồng độ pha thuốc tối đa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.dilution_concentration_max),callback:function ($$v) {_vm.$set(_vm.form, "dilution_concentration_max", $$v)},expression:"form.dilution_concentration_max"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nồng độ pha thuốc tối thiểu"}},[_c('validation-provider',{attrs:{"name":"Nồng độ pha thuốc tối thiểu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.dilution_concentration_min),callback:function ($$v) {_vm.$set(_vm.form, "dilution_concentration_min", $$v)},expression:"form.dilution_concentration_min"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)]),_c('b-row',[_c('b-col',[_c('b-card-actions',{staticClass:"margin-none",attrs:{"no-body":"","title":"Hướng dẫn sử dụng thuốc","action-collapse":""}},[_c('b-card',{staticClass:"mb-0",attrs:{"bg-variant":"light"}},[_c('b-form-group',{staticClass:"mt-3",attrs:{"label":""}},[_c('validation-provider',{attrs:{"name":"Hướng dẫn sử dụng thuốc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"height":"45px"},attrs:{"placeholder":"Nhập hướng dẫn sử dụng thuốc"},model:{value:(_vm.form.guide_line),callback:function ($$v) {_vm.$set(_vm.form, "guide_line", $$v)},expression:"form.guide_line"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex align-items-center justify-content-end mt-2"},[_c('b-button',{attrs:{"disabled":_vm.isLoading,"variant":"primary"},on:{"click":_vm.handleCreateMedicine}},[_vm._v(_vm._s(_vm.isUpdate?"Cập nhật":"Tạo mới"))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }