import store from "@/store";
import { ref } from "@vue/composition-api";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
import Vue from "vue";

export default function addProduct() {
  const toast = useToast();

  const DEFAULT_FORM = {
    product_master_id: "",
    product_code: "",
    product_hi_code: "",
    name: "",
    unit_id: "",
    packing: "",
    active_ingredient: "",
    quality: "",
    concentration: "",
    volume: "",
    route_of_use: "",
    country: "",
    manufacturer: "",
    supplier: "",
    lot_number: "",
    registration_number: "",
    expiry_date_day: "",
    expiry_date_month: "",
    expiry_date_year: "",
    atc_code: "",
    bid_win_number: "",
    bid_package: "",
    bid_group: "",
    bid_year: "",
    bid_order_no: "",
    national_pharmacy_code: "",
    pharma_product_type_id: "",
    insurance_cost_group: "",
    price_in: "",
    vat_in: "",
    price_out: "",
    vat_out: "",
    price_health_insurance: "",
    price_hospital_fee: "",
    price_service: "",
    percent_insurance_portability: "",
    percent_insurance_uninsured: "",
    expiration_warning: null,
    inventory_warning: "",
    group_mgnt: "",
    group_research: "",
    group_pathological: "",
    group_abcven: "",
    group_pharmacological: "",
    group_sub_pharmacological: "",
    is_lock: "",
    is_pharmacy: "",
    dosage_form: "",
    issuer_code: "",
    medicine_type: "",
    bidding_type: "",
    bidding_form: "",
    prescription_required: "",
    category_id: null,
    brand_id: null,
    sku: "",
    type_id: "",
    dosage_form: "",
    dosage_form_2: "",
    reconstitution: "",
    reconstitution_v: "",
    dilution: "",
    dilution_v: "",
    dilution_concentration_max: "",
    dilution_concentration_min: "",
    guide_line: "",
    pharma_id: null,
  };

  const form = ref({ ...DEFAULT_FORM });
  const isLoading = ref(false);

  const addPharmaProduct = async (data) => {
    try {
      isLoading.value = true;
      const params = handleProcessData(data);

      const result = Object.fromEntries(
        Object.entries(params).filter(([_, v]) => !!v)
      );

      await store.dispatch("phamarProduct/addPharmaProduct", result);

      toast({
        component: ToastificationContent,
        props: {
          title: "Tạo sản phẩm thành công",
          icon: "CheckCircleIcon",
          variant: "success",
        },
      });

      router.replace({ name: "product-manager" });
    } catch (error) {
      console.log(error);
      toast({
        component: ToastificationContent,
        props: {
          title: "Có lỗi xảy ra trong quá trình tạo mới",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    } finally {
      isLoading.value = false;
    }
  };

  const getMedicineDetail = async (id) => {
    try {
      isLoading.value = true;
      const response = await store.dispatch(
        "phamarProduct/getMedicineDetail",
        id
      );
      const responseData = response.data || {};
      form.value = {
        ...responseData,
        unit_id: responseData?.unit_info,
        active_ingredient: responseData?.active_ingredient_info,
        bidding_form: responseData?.bidding_form_info,
        bidding_type: responseData?.bidding_type_info,
        country: responseData?.country_info,
        dilution: responseData?.dilution_info,
        group_abcven: responseData?.group_acbven_info,
        group_mgnt: responseData?.group_manager_info,
        group_pathological: responseData?.group_pathological_info,
        group_pharmacological: responseData?.group_pharmalogical_info,
        group_research: responseData?.group_researcher_info,
        group_sub_pharmacological: responseData?.group_sub_pharmalogical_info,
        insurance_cost_group: responseData?.heath_insurance_cost_info,
        manufacturer: responseData?.manufacturer_info,
        medicine_type: responseData?.medicine_type_info,
        pharma_product_type_id: responseData?.product_type_info,
        reconstitution: responseData?.reconstitution_info,
        supplier: responseData?.supplier_info,
        unit_id: responseData?.unit_info,
        route_of_use: responseData?.use_route_info,
      };

      form.value = Object.fromEntries(
        Object.entries(form.value).filter(([_, v]) => !!v)
      );
    } catch (error) {
      console.log(error);
      toast({
        component: ToastificationContent,
        props: {
          title: "Có lỗi xảy ra trong quá trình lấy thông tin thuốc",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    } finally {
      isLoading.value = false;
    }
  };

  const updateMedicine = async (id, data) => {
    try {
      isLoading.value = true;

      const params = handleProcessData(data);

      await store.dispatch("phamarProduct/updateMedicine", { id, params });
      toast({
        component: ToastificationContent,
        props: {
          title: "Cập nhật thuốc thành công",
          icon: "CheckCircleIcon",
          variant: "success",
        },
      });
      router.replace({ name: "product-manager" });
    } catch (error) {
      console.log(error);
      toast({
        component: ToastificationContent,
        props: {
          title: "Có lỗi xảy ra khi cập nhật thuốc",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    } finally {
      isLoading.value = false;
    }
  };

  const handleProcessData = (data) => {
    const newData = {
      ...data,
      pharma_id: Vue.prototype.$pharmacyInfo.id,
      expiration_warning: Number(data?.expiration_warning),
      expiry_date_day: Number(data?.expiry_date_day),
      expiry_date_month: Number(data?.expiry_date_month),
      expiry_date_year: Number(data?.expiry_date_year),
      inventory_warning: Number(data?.inventory_warning),
      is_lock: Number(data?.is_lock),
      is_pharmacy: Number(data?.is_pharmacy),
      lot_number: data?.lot_number,
      percent_insurance_portability: Number(
        data?.percent_insurance_portability
      ),
      percent_insurance_uninsured: Number(data?.percent_insurance_uninsured),
      pharma_active_ingredient_id: Number(data?.pharma_active_ingredient_id),
      pharma_bidding_form_id: Number(data?.pharma_bidding_form_id),
      pharma_bidding_type_id: Number(data?.pharma_bidding_type_id),
      pharma_group_abcven_id: Number(data?.pharma_group_abcven_id),
      pharma_group_manager_id: Number(data?.pharma_group_manager_id),
      pharma_group_pathological_id: Number(data?.pharma_group_pathological_id),
      pharma_group_pharmacological_id: Number(
        data?.pharma_group_pharmacological_id
      ),
      pharma_group_researcher_id: Number(data?.pharma_group_researcher_id),
      pharma_group_sub_pharmacological_id: Number(
        data?.pharma_group_sub_pharmacological_id
      ),
      pharma_manufacturer_id: Number(data?.pharma_manufacturer_id),
      pharma_medicine_type_id: Number(data?.pharma_medicine_type_id),
      pharma_product_type_id: Number(data?.pharma_product_type_id),
      pharma_supplier_id: Number(data?.pharma_supplier_id),
      pharma_use_route_id: Number(data?.pharma_use_route_id),
      prescription_required: Number(data?.prescription_required),
      price: Number(data?.price?.toString()?.replaceAll(",", "")) || 0,
      price_health_insurance:
        Number(data?.price_health_insurance?.toString()?.replaceAll(",", "")) ||
        0,
      price_hospital_fee:
        Number(data?.price_hospital_fee?.toString()?.replaceAll(",", "")) || 0,
      price_in: Number(data?.price_in?.toString()?.replaceAll(",", "")) || 0,
      price_out: Number(data?.price_out?.toString()?.replaceAll(",", "")) || 0,
      price_service:
        Number(data?.price_service?.toString()?.replaceAll(",", "")) || 0,
      product_master_id: Number(data?.product_master_id),
      product_unit_id: Number(data?.product_unit_id),
      type_id: Number(data?.type_id),
      unit_id: Number(data?.unit_id),
      vat_in: Number(data?.vat_in),
      vat_out: Number(data?.vat_out),
      volume: data?.volume,
      unit_id: data?.unit_id?.id,
      active_ingredient: data?.active_ingredient?.id,
      bidding_form: data?.bidding_form?.id,
      bidding_type: data?.bidding_type?.id,
      country: data?.country?.id,
      dilution: data?.dilution?.id,
      group_abcven: data?.group_abcven?.id,
      group_mgnt: data?.group_mgnt?.id,
      group_pathological: data?.group_pathological?.id,
      group_pharmacological: data?.group_pharmacological?.id,
      group_research: data?.group_research?.id,
      group_sub_pharmacological: data?.group_sub_pharmacological?.id,
      insurance_cost_group: data?.insurance_cost_group?.id,
      manufacturer: data?.manufacturer?.id,
      medicine_type: data?.medicine_type?.id,
      pharma_product_type_id: data?.pharma_product_type_id?.id,
      reconstitution: data?.reconstitution?.id,
      supplier: data?.supplier?.id,
      unit_id: data?.unit_id?.id,
      route_of_use: data?.route_of_use?.id,
    };

    // const result = Object.fromEntries(
    //   Object.entries(newData).filter(([_, v]) => !!v)
    // );

    return newData;
  };

  return {
    form,
    DEFAULT_FORM,
    isLoading,
    addPharmaProduct,
    getMedicineDetail,
    updateMedicine,
  };
}
